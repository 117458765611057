<template>
  <vx-card :title="title">
    <vs-tabs :value="activeTab">
      <vs-tab label="Waiting">
        <Waiting></Waiting>
      </vs-tab>
      <vs-tab label="Approved">
        <Approved></Approved>
      </vs-tab>
      <vs-tab label="Rejected">
        <Rejected></Rejected>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Waiting from "./Waitting.vue"
import Approved from "./Approved.vue"
import Rejected from "./Rejected.vue"

export default {
  components: {
    Waiting,
    Approved,
    Rejected
  },
  data() {
    return {
      title: "Missing Stock Settlement Approval",
      activeTab : this.$route.query.tab ? this.$route.query.tab : 0,
    };
  },
};
</script>
